import {pathActive} from 'helpers'
import {useLocalValue} from 'hooks'
import {useEffect} from 'react'
import {forceCheck} from 'react-lazyload'

export const useForceCheck = (envs, teamFilterState, search) => {
	const planningQuickPlan = useLocalValue('planningQuickPlan')
	const planningShowEvents = useLocalValue('planningShowEvents')
	const planningForecast = useLocalValue('planningForecast')
	const planningShowImproductive = useLocalValue('planningShowImproductive')
	const planningShowWeather = useLocalValue('planningShowWeather')
	const planningShowHourRow = useLocalValue('planningShowHourRow')

	const isPerTeam = pathActive('per_team', false)
	useEffect(
		() => isPerTeam && forceCheck(),
		[
			envs,
			teamFilterState,
			planningQuickPlan,
			planningShowEvents,
			planningForecast,
			planningShowImproductive,
			planningShowWeather,
			planningShowHourRow,
			search,
		],
	)
}

import utils from '@eitje/web_utils'
import {EitjeModal} from 'components/common'
import {t} from 'cores/integrations/index'
import {getEnvVar} from 'helpers'
import {useQueryParams} from 'hooks'
import {store} from 'index'
import {useEffect, useMemo} from 'react'

const useRedirect = ({id, scopes = []}) => {
	const state = useMemo(() => utils.randomId(), [])
	const {connection_id} = useQueryParams()
	useEffect(() => {
		store.dispatch({type: 'SET_VALUE', name: `${id}State`, payload: state})
		store.dispatch({type: 'SET_VALUE', name: 'connectionId', payload: connection_id})
	}, [])
	const url = getEnvVar(`${id}_url`)
	const clientId = getEnvVar(`${id}_client_id`)
	const redirectUri = encodeURIComponent(`${window.location.origin}/integrations/${id}/oauth`)
	const scope = scopes.join(' ')
	const base = `${url}?client_id=${clientId}&redirect_uri=${redirectUri}`
	const extra = `&response_type=code&scope=${scope}&state=${state}`
	const finalUrl = `${base}${extra}`

	setTimeout(() => window.location.replace(finalUrl), 700)
}

export const Oauth = props => {
	const {name, id} = props
	useRedirect(props)
	return (
		<EitjeModal name={name} hideHeader hideFooter className="integration-redirect-modal">
			<img className="integration-redirect-image" src={`/images/integrations/${id}.png`} />
			<h3 className="integration-redirect-title"> {t('oauth_redirect.title')} </h3>
			<h5 className="integration-redirect-text"> {t('oauth_redirect.subtitle')} </h5>
		</EitjeModal>
	)
}

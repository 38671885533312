import {setArbitrary} from 'actions'
import {navigateModal} from 'actions/routing'
import {Button} from 'antd'
import {Text} from 'common/components'
import {useIntegrationConstants} from 'cores/integrations'
import {useParams} from 'hooks'
import useAsyncEffect from 'hooks/use_async_effect'
import {t} from 'initializers/i18n'
import {BaseConnection} from 'models'
import QueryString from 'query-string'
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'

export const OauthActivated = ({location}) => {
	const {providerName} = useParams()
	const {type} = useIntegrationConstants(providerName)
	const [loading, setLoading] = useState(true)
	const [id, setId] = useState(false)
	const savedState = useSelector(state => state.arbitrary[`${providerName}State`])
	const {code, state} = QueryString.parse(location.search)
	const isValid = !!(savedState === state && code)
	const connectionId = useSelector(state => state.arbitrary.connectionId)
	const conn = BaseConnection.find(connectionId)
	useAsyncEffect(async () => {
		if (isValid) {
			const params = {code, provider: providerName}
			let res
			if (conn.id) {
				res = await conn.update(params)
			} else {
				res = await BaseConnection.create(params)
			}

			setLoading(false)
			if (res.ok) {
				setArbitrary('connectionId', null)
				setId(res?.data.item.id)
				// save rev groups
			}
		}
	}, [])

	if (id) return <Redirecter type={type} providerName={providerName} id={id} />
	return (
		<div
			className="shadow lightspeed"
			style={{
				width: '25%',
				border: '1px solid #eee',
				margin: 'auto',
				borderRadius: 4,
				backgroundColor: 'white',
				padding: '80px 24px 40px 24px',
				marginTop: 120,
				boxShadow: '0 5px 20px 0 rgba(34,43,55,.15)',
			}}
		>
			<div style={{width: '100%', textAlign: 'center', margin: 'auto'}}>
				<img style={{width: 80, height: 80, marginBottom: 40}} src="/images/logoCircle.png" />

				{loading && <span style={{display: 'block'}}>{t('integrations.oauth.pending_title')}</span>}

				{!id && !loading && (
					<div>
						<span style={{marginBottom: 24, display: 'block'}}>{t('integrations.oauth.failed_title')}</span>
						<Button>
							<Link to="/integrations">
								<Text>{t('common.go_to', {to: t('header.integrations')})}</Text>
							</Link>
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}

const Redirecter = ({id, type, providerName}) => {
	useEffect(() => {
		navigateModal(`/integrations/${providerName}/${id}/setup_${type}`, {bgPath: `/integrations/my/${providerName}/`})
	}, [])
	return null
}

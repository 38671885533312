import {useFilteredShifts, useEditableFilteredShifts, useNonPlanShifts, getTotals} from 'cores/time_registration'
import {NAMESPACE, useCheckboxLeft} from '.'
import {Association, PlanningShift} from 'models'

export function useDayItems(date) {
	const dayData = useTotals(date)
	return items(dayData)
}

export function items(data) {
	const shared = {type: 'greySubtitle', namespace: NAMESPACE}
	return [
		{value: data.totalWorkedString, localValue: 'hoursShowTotalWorked', ...shared},
		{value: data.totalPlannedString, localValue: 'hoursShowPlannedWorkedDiff', ...shared},
	]
}

export function useTotals(dateOrRange) {
	const regShifts = useNonPlanShifts()
	const dayRegShifts = regShifts.where({date: dateOrRange})

	// To show the amount of planned hours, use both the planning shift records directly (for which there are no
	// registered shifts yet), and mix them with planning shifts which belong to approved registered shifts.
	const {planShifts: unapprovedPlanShifts} = useFilteredShifts()
	const approvedPlanShiftIds = dayRegShifts._map('planning_shift_id').filter(Boolean).uniq()
	const approvedPlanShifts = PlanningShift.where(approvedPlanShiftIds)
	const planShifts = new Association([...approvedPlanShifts, ...unapprovedPlanShifts])
	const dayPlanShifts = planShifts.where({date: dateOrRange})

	return getTotals({regShifts: dayRegShifts, planShifts: dayPlanShifts})
}

export function useDayCheckbox(date) {
	const shifts = useEditableFilteredShifts().where({date})
	return useCheckboxLeft(shifts)
}

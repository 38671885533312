import {PopoutCard} from '@eitje/web_components'
import {DeletePopout, Layout, Text, Title} from 'common/components'
import EitjeButton from 'common/components/eitje_button'
import {EitjeDropdown} from 'common/components/eitje_dropdown'
import {ModalLink} from 'components/routing'
import {t, useIntegration} from 'cores/integrations/index'
import {getEntityName} from 'helpers'
import {t as baseT} from 'initializers/i18n'
import {ExtEnvironment} from 'models/index'
import {Fragment} from 'react'
import './index.less'

const Integration = ({item}) => {
	const mergedItem = {...useIntegration(item.id), ...item}
	const extEnvironments = ExtEnvironment.where(item.ext_environment_ids)
	const envs = extEnvironments._map('environments').flat().uniq('id')
	let {status} = mergedItem

	const issue = status == 'issue'
	const authIssue = item.authIssue()
	const url = issue ? item.issuesUrl() : item.setupUrl()

	const isSettingUp = status == 'partly_operational' || status == 'not_operational'
	const title = isSettingUp
		? `${baseT('common.settings_unfinished')}...`
		: getEntityName(envs, baseT('environments'), {Component: Title, componentProps: {maxLen: 1, weight: 'light', truncate: true}})

	const Wrapper = authIssue && item.name == 'Nmbrs' ? PopoutCard : isSettingUp || issue ? ModalLink : Fragment

	return (
		<Wrapper to={url} title={t('nmbrs.auth_problem')}>
			<Layout
				className="provider-connection"
				horizontal="spaceBetween"
				colorSet="grey-bordered"
				padding={12}
				type="card"
				width={800}
				minHeight={72}
				baseColor={issue && 'red'}
			>
				<Layout baseColor={issue && 'red'} direction="vertical" grow>
					<Title weight="light" color={issue && 'red'}>
						{title}
					</Title>
					{!isSettingUp && <SubInfo error={issue} item={item} />}
				</Layout>
				<Layout>
					<ActionButton item={mergedItem} colorSet={isSettingUp || issue ? 'color-bordered-white' : 'grey-bordered'} />
					<OptionsDropdown item={mergedItem} issue={issue} isSettingUp={isSettingUp} />
				</Layout>
			</Layout>
		</Wrapper>
	)
}

const SubInfo = ({item, error}) => {
	const {last_sync_datetime, external_id} = item
	return (
		<Layout baseColor="red" width="full">
			<Text small color={error ? 'red' : 'darkGrey'} truncate>
				{last_sync_datetime && (
					<>
						{t('last_synced_at')}: {baseT('common.onDateTime', {date: last_sync_datetime})}
						{external_id && ' | '}
					</>
				)}
				{external_id && `ID: ${external_id}`}
			</Text>
		</Layout>
	)
}

const ActionButton = ({item, ...rest}) => {
	let {status} = item
	let Component = SetupButton
	if (status == 'issue') Component = IssueButton
	if (status == 'operational' && item.type != 'loyalty') {
		Component = item.hasPush ? ExportHours : SyncButton
	}
	if (item.authIssue()) {
		Component = ReAuthButton
	}

	return <Component item={item} {...rest} />
}

export const SyncButton = ({item, ...rest}) => {
	const prefix = item.isHr() ? 'hr' : 'pos'
	const {status} = useIntegration(item.id)
	const disabled = status != 'operational' && t('connection_not_setup')

	return (
		<EitjeButton iconLeft="arrow-circle" onClick={() => item.sync()} disabled={disabled} {...rest}>
			{t(`${prefix}.sync_now`)}
		</EitjeButton>
	)
}

const SetupButton = ({item, ...rest}) => {
	const {currentStep, maxStep} = item
	return (
		<EitjeButton iconLeft="three-sliders" modalLink={item.setupUrl()} {...rest}>
			{baseT('common.configure')} ({currentStep}/{maxStep})
		</EitjeButton>
	)
}

const IssueButton = ({item, ...rest}) => {
	return (
		<EitjeButton iconLeft="external-link" baseColor="red" {...rest}>
			{baseT('common.view_and_solve_problem')}
		</EitjeButton>
	)
}

const ReAuthButton = ({item, ...rest}) => {
	return (
		<EitjeButton modalLink={item.reAuthUrl()} iconLeft="external-link" baseColor="red" {...rest}>
			{t('reintegrate')}
		</EitjeButton>
	)
}

const OptionsDropdown = ({item, issue, isSettingUp}) => {
	const {status, sanitized_type, hasPush} = item
	const prefix = item.isHr() ? 'hr' : 'pos'
	const syncElement = {element: <Text onClick={() => item.sync()} children={t(`${prefix}.sync_now`)} />, icon: 'arrow-circle'}
	const setupElement = {element: <ModalLink to={item.setupUrl()} t="common.configure" />, icon: 'pencil'}
	const deleteElement = {
		element: (
			<DeletePopout onDelete={item.destroy}>
				<Text t="delete" />
			</DeletePopout>
		),
		icon: 'trash',
	}

	const exportUsersElement = {
		element: <ModalLink to={item.exportUsersUrl()} t={{key: 'integrations.hr.export_users', count: 2}} />,
		icon: 'upload',
	}

	const pastSyncsModal = {
		element: (
			<ModalLink to={item.syncHistoryUrl()}>
				<Text t="integrations.operations_modal" />
			</ModalLink>
		),
		icon: 'arrow-circle',
	}

	const elements = [
		sanitized_type == 'fooks' && exportUsersElement,
		status == 'operational' && hasPush && syncElement,
		status == 'operational' && setupElement,
		pastSyncsModal,
		deleteElement,
	]

	return (
		<div className="integration-options-dropdown-trigger" onClick={e => e.stopPropagation()}>
			<EitjeDropdown visibleAfterClick elements={elements}>
				<EitjeButton
					width="full"
					iconLeft="three-dots"
					baseColor={issue && 'red'}
					colorSet={(isSettingUp || issue) && 'color-bordered-white'}
				/>
			</EitjeDropdown>
		</div>
	)
}

const ExportHours = ({item}) => {
	return <EitjeButton iconLeft="upload" modalLink={item.exportHoursUrl()} t={{key: 'integrations.hr.export_to', integName: item.name}} />
}

export default Integration
